/**
 * External dependencies
 */
import React, { useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import MainNavigation from './MainNavigation';
import MetaNavigation from './MetaNavigation';
import VisuallyHidden from '../VisuallyHidden/VisuallyHidden';
import { ReactComponent as Logo } from './logo-biz.svg';
import { HeaderContext } from './HeaderContext';
import SearchForm from './SearchForm';

import './Header.scss';
import Button from '../Button/Button';
import SearchButton from './SearchButton';

const Header = () => {
	const location = useLocation();

	const {
		isMenuOpen,
		openMenu,
		closeMenu,
	} = useContext( HeaderContext );

	useEffect( () => {
		if ( ! isMenuOpen ) {
			return;
		}

		const closeOnEsc = ( event ) => {
			if ( 27 === event.keyCode ) { // Escape key.
				closeMenu();
			}
		};

		window.addEventListener( 'keydown', closeOnEsc );

		// Remove event listeners on cleanup.
		return () => {
			window.removeEventListener( 'keydown', closeOnEsc );
		};
	}, [ closeMenu, isMenuOpen ] );

	return (
		<header className="app-header">
			<div className="app-header__inner">
				<SearchForm />
				<Link className="app-header__home-link" to="/">
					<VisuallyHidden>
						{ '/' === location.pathname ? <h1 className="app-header__title">Berufswahl-Portal</h1> : <p className="app-header__title">Berufswahl-Portal</p> }
					</VisuallyHidden>
					<Logo className="app-header__logo" role="img" aria-hidden="true" focusable="false" />
				</Link>
				<div className={ classnames( 'app-header__navigation', { 'app-header__navigation--is-open': isMenuOpen } ) }>
					<MetaNavigation onClick={ closeMenu } />
					<MainNavigation onClick={ closeMenu } />
				</div>
				<SearchButton className="mobile-search-open" />
				<Button onClick={ isMenuOpen ? closeMenu : openMenu } className="mobile-menu-open">
					<span aria-hidden="true" className="lines">
						<span className="line first"></span>
						<span className="line second"></span>
						<span className="line third"></span>
					</span>
					Menu
				</Button>
			</div>
		</header>
	);
};

export default Header;
