/**
 * External dependencies
 */
import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { useDispatch } from '@wordpress/data';

/**
 * Internal dependencies
 */
import Form from '../Form/Form';
import TextControl from '../TextControl/TextControl';
import Button from '../Button/Button';
import apiFetch from '../../utils/apiFetch';

import './LoginForm.scss';
import useAnalytics from '../../hooks/useAnalytics';

const LOGIN_ERROR_NOTICE_ID = 'login-error-notice-id';

interface LoginFormProps {
	className?: string;
	redirectTo?: string;
}

const LoginForm = ( {
	redirectTo = null,
	className,
}: LoginFormProps ) => {
	const [ isRequesting, setIsRequesting ] = useState( false );
	const [ email, setEmail ] = useState( '' );
	const [ error, setError ] = useState( null );
	const [ success, setSuccess ] = useState( false );
	const {
		createErrorNotice,
		removeNotice,
	} = useDispatch( 'biz/data' );
	const { trackEvent } = useAnalytics();

	useEffect( () => {
		if ( ! error ) {
			removeNotice( LOGIN_ERROR_NOTICE_ID );
		} else if ( error.code && 'rest_authentication_invalid_username' === error.code ) {
			createErrorNotice( 'Wir konnten leider kein Profil zu der angegeben E-Mail-Adresse finden.', { id: LOGIN_ERROR_NOTICE_ID } );
		} else {
			createErrorNotice( 'Bitte überprüfe deine Angaben und versuche es erneut.', { id: LOGIN_ERROR_NOTICE_ID } );
		}
	}, [ createErrorNotice, error, removeNotice ] );

	const sendLoginRequest = async () => {
		setIsRequesting( true );

		const data: any = { email };
		if ( redirectTo ) {
			data.redirect_to = redirectTo;
		}

		try {
			const response = await apiFetch( {
				path: '/wp-json/biz/v1/login-requests',
				method: 'POST',
				data,
			} );

			if ( 'success' === response ) {
				setSuccess( true );
			} else {
				throw new Error( 'Unbekannter Fehler' );
			}
		} catch ( e ) {
			setError( e );
		} finally {
			setIsRequesting( false );
		}
	};

	const resetState = () => {
		setSuccess( false );
		setError( null );
	};

	const onSubmit = ( event ) => {
		event.preventDefault();

		trackEvent( {
			category: 'engagement',
			action: 'submit_form',
			label: 'login',
		} );

		resetState();
		sendLoginRequest();
	};

	if ( success ) {
		return (
			<>
				<p>
					Öffne bitte jetzt die E-Mail von uns und klicke den Link zur Bestätigung.<br />
					Bitte überprüfe auch deinen Spam-, Junk- oder Bulk-Mail-Ordner.
				</p>
				<p>
					<strong>Hinweis: </strong> Zu deiner Sicherheit ist der Link nur eine Stunde gültig und kann nur einmal verwendet werden.
				</p>

				<p>Keine E-Mail bekommen? <Button isLink onClick={ resetState }>E-Mail erneut senden</Button>.</p>
			</>
		);
	}

	const classes = classnames( 'login-form', className );

	return (
		<Form onSubmit={ onSubmit } className={ classes }>
			<p>Wir senden dir an deine hinterlegte E-Mail-Adresse einen Link, um die Anmeldung abzuschliessen.</p>
			<TextControl
				label="E-Mail-Adresse"
				type="email"
				isInvalid={ !! error }
				value={ email }
				disabled={ isRequesting }
				required
				onChange={ ( value ) => {
					setEmail( value );
					setError( null );
				} }
			/>
			<Button
				disabled={ isRequesting }
				isBusy={ isRequesting }
				type="submit"
				isPrimary
				isLarge
			>
				Link per E-Mail senden
			</Button>

			<p className="login-form__registration-hint">
				Neu im Portal? <Button isLink href="/registrierung">Registriere dich jetzt</Button>.
			</p>
		</Form>
	);
};

export default LoginForm;
