/**
 * External dependencies
 */
import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Internal dependencies
 */
import LoginModal from '../LoginModal/LoginModal';
import './Footer.scss';

const Footer = () => {
	return (
		<>
			<footer className="app-footer">
				<div className="app-footer__colophon">
					<div className="app-footer__colophon-inner">
						<ul className="colophon__list">
							<li className="colophon__list-item">&copy; { ( new Date().getFullYear() ) } berufswahl.zh.ch</li>
							<li className="colophon__list-item colophon__list-item--is-light">{ process.env.REACT_APP_VERSION }</li>
						</ul>
						<ul className="colophon__list">
							<li className="colophon__list-item"><Link to="/video-berufswahl-portal-funktionen">So funktioniert’s</Link></li>
							<li className="colophon__list-item"><Link to="/kontakt">Kontakt</Link></li>
							<li className="colophon__list-item"><Link to="/biz-newsletter">Newsletter</Link></li>
							<li className="colophon__list-item"><Link to="/datenschutz">Datenschutz</Link></li>
							<li className="colophon__list-item"><Link to="/impressum">Impressum</Link></li>
						</ul>
					</div>
				</div>
			</footer>
			<LoginModal />
		</>
	);
};

export default Footer;
