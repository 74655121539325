/**
 * External dependencies
 */
import React, {
	useContext,
	useState,
	useEffect,
	useRef,
} from 'react';
import { useHistory } from 'react-router-dom';
import { X as CloseIcon } from 'react-feather';

/**
 * Internal dependencies
 */
import { HeaderContext } from './HeaderContext';
import TextControl from '../TextControl/TextControl';
import VisuallyHidden from '../VisuallyHidden/VisuallyHidden';
import Button from '../Button/Button';

import './SearchForm.scss';

const SearchForm = () => {
	const history = useHistory();
	const {
		isSearchFormOpen,
		closeSearchForm,
	} = useContext( HeaderContext );
	const [ keyword, setKeyword	] = useState( '' );

	const inputEl = useRef( null );
	useEffect( () => {
		if ( isSearchFormOpen && inputEl.current ) {
			inputEl.current.focus();
		}
	}, [ isSearchFormOpen ] );

	useEffect( () => {
		const closeOnEsc = ( event ) => {
			if ( 27 === event.keyCode ) { // Escape key.
				closeSearchForm();
			}
		};

		window.addEventListener( 'keydown', closeOnEsc );

		// Remove event listeners on cleanup.
		return () => {
			window.removeEventListener( 'keydown', closeOnEsc );
		};
	}, [ closeSearchForm ] );

	if ( ! isSearchFormOpen ) {
		return null;
	}

	const onSubmit = ( event ) => {
		event.preventDefault();

		if ( ! keyword.trim().length ) {
			return;
		}

		history.push( '/suche?s=' + window.encodeURIComponent( keyword.trim() ) );
		closeSearchForm();
	};

	const onChange = ( value ) => {
		setKeyword( value );
	};

	return (
		<form className="global-search-form" onSubmit={ onSubmit }>
			<button className="global-search-form__close" type="button" onClick={ closeSearchForm }>
				<CloseIcon role="img" aria-hidden="true" focusable="false" /> <VisuallyHidden>Schliessen</VisuallyHidden>
			</button>

			<div className="global-search-form__inner">
				<label className="base-control__label global-search-form__label" htmlFor="global-search-form-input">Suche nach Berufen, Informationen, Veranstaltungen, Lehrstellen oder Firmen</label>
				<TextControl
					className="global-search-form__input"
					id="global-search-form-input"
					placeholder="Suchbegriff"
					value={ keyword }
					type="text"
					onChange={ onChange }
					ref={ inputEl }
				/>
				<Button className="global-search-form__submit" isPrimary type="submit">Suchen</Button>
			</div>
		</form>
	);
};

export default SearchForm;
