/**
 * External dependencies
 */
import React from 'react';
import { useSelect } from '@wordpress/data';

/**
 * Internal dependencies
 */
import { Nav, NavLink } from '../Nav';
import { ReactComponent as Arrow } from '../Dropdown/arrow.svg';
import { Profile } from '../../store/types';

import './MetaNavigation.scss';

const MetaNavigation = ( { onClick } ) => {
	const { profile }: { profile: Profile } = useSelect( ( select ) => {
		const {
			isAuthenticated,
			getProfile,
		} = select( 'biz/data' );

		return { profile: isAuthenticated() ? getProfile() : null };
	}, [] );

	return (
		<Nav className="meta-navigation">
			<ul>
				<li>
					<NavLink onClick={ onClick } to="/fuer-unterstuetzungspersonen">Für Unterstützungspersonen</NavLink>
				</li>
				<li>
					<NavLink onClick={ onClick } to="/berufswahlfahrplan">Fahrplan</NavLink>
				</li>
				<li>
					<NavLink onClick={ onClick } exact to="/berufsinteressentest">Interessentest</NavLink>
				</li>
				<li>
					<NavLink onClick={ onClick } exact to="/berufsberatung">Berufsberatung</NavLink>
				</li>
				{
					! profile && (
						<li className="meta-navigation__account">
							<ul>
								<li>
									<NavLink onClick={ onClick } to="/anmelden">Anmelden</NavLink>
								</li>
								<li>
									<NavLink className="navigation__item--registration" onClick={ onClick } to="/registrierung">Registrieren</NavLink>
								</li>
							</ul>
						</li>
					)
				}
				{
					!! profile && (
						<>
							<li className="meta-navigation__dropdown" tabIndex={ 0 }>
								<div className="meta-navigation__dropdown--button">
									{ profile?.avatar_url ?
										<img src={ profile.avatar_url } alt="Dein Profilbild" height="24" width="24" /> :
										<div className="meta-navigation__dropdown--placeholder"></div>
									}
									<span>{ profile?.name || 'Wird geladen ...' }</span>
									<Arrow role="img" focusable="false" aria-hidden="true" />
								</div>

								<ul>
									<li>
										<NavLink onClick={ onClick } to="/profil">
											Profil
										</NavLink>
									</li>
									{ 'organization_editor' !== profile?.role &&
										<li>
											<NavLink onClick={ onClick } to="/profil/aktivitäten">
												Aktivitäten
											</NavLink>
										</li>
									}
									{ 'young_person' === profile?.role &&
										<li>
											<NavLink onClick={ onClick } to="/profil/cv">
												Lebenslauf
											</NavLink>
										</li>
									}
									<li>
										<NavLink onClick={ onClick } to="/abmelden">Abmelden</NavLink>
									</li>
								</ul>
							</li>
						</>
					)
				}
			</ul>
		</Nav>
	);
};

export default MetaNavigation;
