/**
 * External dependencies
 */
import React, {
	lazy,
	Suspense,
} from 'react';
import { Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useSelect } from '@wordpress/data';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import Header from './components/Header/Header';
import { HeaderProvider } from './components/Header/HeaderContext';
import Footer from './components/Footer/Footer';
import Notices from './components/Notices/Notices';
import Loader from './components/Loader/Loader';
import SentryErrorBoundary from './components/SentryErrorBoundary/SentryErrorBoundary';

const Dashboard = lazy( () => import( './pages/Dashboard' ) );
const LoginPage = lazy( () => import( './pages/LoginPage' ) );
const LogoutPage = lazy( () => import( './pages/LogoutPage' ) );
const AuthenticatePage = lazy( () => import( './pages/AuthenticatePage' ) );
const RegistrationPage = lazy( () => import( './pages/RegistrationPage' ) );
const Professions = lazy( () => import( './pages/Professions' ) );
const Apprenticeship = lazy( () => import( './pages/Apprenticeship' ) );
const ProfessionPage = lazy( () => import( './pages/ProfessionPage' ) );
const Apprenticeships = lazy( () => import( './pages/Apprenticeships' ) );
const ApprenticeshipsOffers = lazy( () => import( './pages/ApprenticeshipsOffers' ) );
const Events = lazy( () => import( './pages/Events' ) );
const EventPage = lazy( () => import( './pages/EventPage' ) );
const CareerChoiceTimetablePage = lazy( () => import( './pages/CareerChoiceTimetablePage' ) );
const GlobalSearch = lazy( () => import( './pages/GlobalSearch' ) );
const InterestsCheckPage = lazy( () => import( './pages/InterestsCheckPage' ) );
const Consulting = lazy( () => import( './pages/Consulting' ) );
const ContactConsulting = lazy( () => import( './pages/ContactConsulting' ) );
const Contact = lazy( () => import( './pages/Contact' ) );
const Preview = lazy( () => import( './pages/Preview' ) );
const Profile = lazy( () => import( './pages/Profile' ) );
const ViewProfile = lazy( () => import( './pages/ViewProfile' ) );
const DeleteProfile = lazy( () => import( './pages/DeleteProfile' ) );
const WordPressPage = lazy( () => import( './pages/WordPressPage' ) );
const NotFound = lazy( () => import( './pages/NotFound' ) );
const ProfileShareActionPage = lazy( () => import( './pages/ProfileShareActionPage' ) );
const ActivitiesPage = lazy( () => import( './pages/ActivitiesPage' ) );
const ResumePage = lazy( () => import( './pages/ResumePage' ) );
const ViewResume = lazy( () => import( './pages/ViewResume' ) );
const ViewActivities = lazy( () => import( './pages/ViewActivities' ) );
const OrganizationPage = lazy( () => import( './pages/OrganizationPage' ) );
const OrganizationEditPage = lazy( () => import( './pages/OrganizationEditPage' ) );
const OrganizationSlotStatusActionPage = lazy( () => import( './pages/OrganizationSlotStatusActionPage' ) );
const Organizations = lazy( () => import( './pages/Organizations' ) );

const IS_IE = window.navigator.userAgent.indexOf( 'Trident' ) >= 0;

const APPRENTICESHIP_AVAILABLE_YEARS = process.env.REACT_APP_APPRENTICESHIP_AVAILABLE_YEARS.split( ',' );

const App = () => {
	const {
		isAuthenticated,
		isProfileFetching,
	} = useSelect( ( select ) => {
		const {
			isAuthenticated: apiIsAuthenticated,
			isProfileFetching: apiIsProfileFetching,
			getProfile,
		} = select( 'biz/data' );

		const isUserAuthenticated = apiIsAuthenticated();

		return {
			isAuthenticated: isUserAuthenticated,
			profile: isUserAuthenticated ? getProfile() : null,
			isProfileFetching: apiIsProfileFetching(),
		};
	}, [] );

	if ( isAuthenticated && isProfileFetching ) {
		return (
			<SentryErrorBoundary component={ 'App.js' }>
				<Helmet titleTemplate="%s | Berufswahl-Portal" defaultTitle="Berufswahl-Portal">
					<meta charSet="utf-8" />
				</Helmet>
				<div className="app">
					<HeaderProvider>
						<Header />
					</HeaderProvider>
					<Notices />
					<main className="app-content">
						<div className="app-content__inner">
							<Loader />
						</div>
					</main>
					<Footer />
				</div>
			</SentryErrorBoundary>
		);
	}

	return (
		<SentryErrorBoundary component={ 'App.js' }>
			<Helmet titleTemplate="%s | Berufswahl-Portal" defaultTitle="Berufswahl-Portal" />
			<div className={ classnames( 'app', { 'env--is-ie': IS_IE } ) }>
				<HeaderProvider>
					<Header />
				</HeaderProvider>
				<Notices />
				<main className="app-content">
					<div className="app-content__inner">
						<Suspense fallback={ <Loader /> }>
							<Switch>
								<Route exact path="/">
									<Dashboard />
								</Route>
								<Route exact path="/anmelden/:authToken">
									<AuthenticatePage />
								</Route>
								<Route exact path="/anmelden">
									<LoginPage />
								</Route>
								<Route exact path="/abmelden">
									<LogoutPage />
								</Route>
								<Route exact path="/registrierung">
									<RegistrationPage />
								</Route>
								<Route exact path="/profil/löschen">
									<DeleteProfile />
								</Route>
								<Route exact path="/profil/aktivitäten">
									<ActivitiesPage />
								</Route>
								<Route exact path="/profil/cv">
									<ResumePage />
								</Route>
								<Route exact path="/profil/:userID/aktivitäten">
									<ViewActivities />
								</Route>
								<Route exact path="/profil/:userID/cv">
									<ViewResume />
								</Route>
								<Route exact path="/profil/:userID">
									<ViewProfile />
								</Route>
								<Route exact path="/profil">
									<Profile />
								</Route>
								<Route exact path="/berufe/:slug">
									<ProfessionPage />
								</Route>
								<Route exact path="/berufe">
									<Professions />
								</Route>
								<Route exact path="/lehrstelle/:slug">
									<Apprenticeship />
								</Route>
								<Route exact path="/schnupperlehre/:slug">
									<Apprenticeship />
								</Route>
								<Route exact path="/schnuppern-lehrstellen">
									<Apprenticeships />
								</Route>
								<Route exact path={ `/schnuppern-lehrstellen/:year(${ APPRENTICESHIP_AVAILABLE_YEARS.join( '|' ) })/:id` }>
									<ApprenticeshipsOffers />
								</Route>
								<Route exact path="/veranstaltungen/:slug">
									<EventPage />
								</Route>
								<Route exact path="/firma/:slug/_slot-status/:apprenticeshipId/:slotId/:requestId/:status/:accessToken">
									<OrganizationSlotStatusActionPage />
								</Route>
								<Route exact path="/firma/:slug/bearbeiten">
									<OrganizationEditPage />
								</Route>
								<Route exact path="/firma/:slug">
									<OrganizationPage />
								</Route>
								<Route exact path="/firmen">
									<Organizations />
								</Route>
								<Route exact path="/veranstaltungen">
									<Events />
								</Route>
								<Route exact path="/berufswahlfahrplan">
									<CareerChoiceTimetablePage />
								</Route>
								<Route exact path="/berufsinteressentest">
									<InterestsCheckPage />
								</Route>
								<Route exact path="/berufsberatung">
									<Consulting />
								</Route>
								<Route exact path="/berufsberatung/:biz/schulhaussprechstunden">
									<ContactConsulting />
								</Route>
								<Route exact path="/berufsberatung/:biz/einzelberatung">
									<ContactConsulting />
								</Route>
								<Route exact path="/kontakt">
									<Contact />
								</Route>
								<Route exact path="/suche">
									<GlobalSearch />
								</Route>
								<Route exact path="/_preview/:pageId/:token">
									<Preview />
								</Route>
								<Route exact path="/_profilfreigabe/:connectionId/:action(accepted|declined)/:confirmationKey">
									<ProfileShareActionPage />
								</Route>
								<Route exact path="/:pagePath([A-Za-z0-9_\-/]+)">
									<WordPressPage />
								</Route>
								<Route>
									<NotFound />
								</Route>
							</Switch>
						</Suspense>
					</div>
				</main>
				<Footer />
			</div>
		</SentryErrorBoundary>
	);
};

export default App;
