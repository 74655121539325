/**
 * External dependencies
 */
import { uniqueId } from 'lodash';

/**
 * Internal dependencies
 */
import {
	DEFAULT_NOTICE_STATUS,
	LOGIN_NOTICE_ID,
	REGISTRATION_NOTICE_ID,
	PROFILE_UPDATE_NOTICE_ID,
	USER_CONNECTION_NOTICE_ID,
	CURRENT_USER_REMEMBERED_ITEM_NOTICE_ID,
	ORGANIZATION_UPDATE_NOTICE_ID,
	EVENT_NOTICE_ID,
	APPRENTICESHIP_SLOT_NOTICE_ID,
} from './constants';

import {
	dispatch,
	apiFetch,
} from './controls';

import {
	getNotificationArgumentsForLoginFail,
	getNotificationArgumentsForRegistrationFail,
	getNotificationArgumentsForUpdateProfileFail,
	getNotificationArgumentsForFormFail,
	getNotificationArgumentsForEventFail,
	getNotificationArgumentsForRequestUserConnectionFail,
	getNotificationArgumentsForDeleteUserConnectionFail,
	getNotificationArgumentsForUpdateUserConnectionFail,
	getNotificationArgumentsForDeleteUserRememberedItemFail,
	getNotificationArgumentsForAddUserRememberedItemFail,
	getNotificationArgumentsForCreateApprenticeshipSlotFail,
	getNotificationArgumentsForUpdateOrganizationFail,
	getNotificationArgumentsForDeleteApprenticeshipSlotFail,
	getNotificationArgumentsForUpdateApprenticeshipSlotFail,
} from './utils/notice-builder';

import {
	AppStateApprenticeshipRequest,
	AppStateApprenticeshipSlot,
	AppStateEventItem,
	Notice,
} from './types';

export function loginRequest() {
	return { type: 'LOGIN_REQUEST' };
}

export function loginSuccess( user ) {
	return {
		type: 'LOGIN_SUCCESS',
		user,
	};
}

export function loginFailure( error ) {
	return {
		type: 'LOGIN_FAILURE',
		error,
	};
}

export function setAuthToken( token ) {
	return {
		type: 'SET_AUTH_TOKEN',
		token,
	};
}

export function removeAuthToken() {
	return { type: 'REMOVE_AUTH_TOKEN' };
}

export function openLoginModal() {
	return { type: 'OPEN_LOGIN_MODAL' };
}

export function closeLoginModal() {
	return { type: 'CLOSE_LOGIN_MODAL' };
}

export function updateProfileRequest() {
	return { type: 'UPDATE_PROFILE_REQUEST' };
}

export function updateProfileSuccess( user ) {
	return {
		type: 'UPDATE_PROFILE_SUCCESS',
		user,
	};
}

export function updateProfileFailure( error ) {
	return {
		type: 'UPDATE_PROFILE_FAILURE',
		error,
	};
}

export function* updateProfile( data ) {
	yield dispatch( 'updateProfileRequest' );

	try {
		const profile = yield apiFetch( {
			path: '/wp-json/biz/v1/users/me',
			method: 'POST',
			data,
		} );

		yield dispatch(
			'receiveProfile',
			profile,
		);

		yield dispatch(
			'createSuccessNotice',
			'Profil aktualisiert.',
			{
				id: PROFILE_UPDATE_NOTICE_ID,
				type: 'snackbar',
			},
		);

		yield dispatch(
			'updateProfileSuccess',
			profile,
		);

		yield dispatch( 'invalidateCurrentUserActivities' );
	} catch ( error ) {
		yield dispatch(
			'createErrorNotice',
			...getNotificationArgumentsForUpdateProfileFail( { error } ),
		);

		yield dispatch(
			'updateProfileFailure',
			{ error },
		);
	}
}

/**
 * Yields action objects used for logging user in.
 */
export function* loginUser( authToken ) {
	yield dispatch(
		'loginRequest',
	);

	// Remove exiting notices.
	yield dispatch(
		'removeNotice',
		LOGIN_NOTICE_ID,
	);

	yield dispatch(
		'removeAuthToken',
	);

	try {
		const response = yield apiFetch( {
			path: '/wp-json/biz/v1/tokens',
			method: 'POST',
			data: { auth_token: authToken },
		} );

		yield dispatch(
			'receiveProfile',
			response.user,
		);

		yield dispatch(
			'setAuthToken',
			response.token,
		);

		yield dispatch(
			'loginSuccess',
			response.user,
		);

		yield dispatch(
			'createSuccessNotice',
			'Erfolgreich angemeldet.',
			{
				id: LOGIN_NOTICE_ID,
				type: 'snackbar',
			},
		);
	} catch ( error ) {
		yield dispatch(
			'createErrorNotice',
			...getNotificationArgumentsForLoginFail( { error } ),
		);

		yield dispatch(
			'loginFailure',
			error,
		);
	}
}

/**
 * Yields action objects used for logging user out.
 */
export function* logoutUser() {
	yield dispatch(
		'removeAuthToken',
	);

	yield dispatch( 'removeAllNotices' );

	yield dispatch(
		'createSuccessNotice',
		'Erfolgreich abgemeldet.',
		{
			id: 'LOGOUT_SUCCESS',
			type: 'snackbar',
		},
	);

	yield dispatch( 'removeProfile' );
	yield dispatch( 'invalidateCurrentUserRememberedItems' );
	yield dispatch( 'invalidateCurrentUserActivities' );
	yield dispatch( 'invalidateUsers' );
	yield dispatch( 'invalidateUserActivities' );
	yield dispatch( 'invalidateUserConnections' );
	yield dispatch( 'invalidateUserRememberedItems' );
}

export function registrationRequest() {
	return { type: 'REGISTRATION_REQUEST' };
}

export function registrationSuccess( user ) {
	return {
		type: 'REGISTRATION_SUCCESS',
		user,
	};
}

export function registrationFailure( error ) {
	return {
		type: 'REGISTRATION_FAILURE',
		error,
	};
}

/**
 * Yields action objects used for registering a new user.
 */
export function* registerUser( data ) {
	yield dispatch(
		'registrationRequest',
	);

	// Remove exiting notices.
	yield dispatch(
		'removeNotice',
		REGISTRATION_NOTICE_ID,
	);

	try {
		const registrationData: any = {
			first_name: data.firstname,
			last_name: data.lastname,
			email: data.email,
			role: data.role,
			terms: data.terms,
		};

		if ( 'teacher' === registrationData.role ) {
			registrationData.school = data.school;
		} else {
			registrationData.residence = data.residence;
		}

		const response = yield apiFetch( {
			path: '/wp-json/biz/v1/users',
			method: 'POST',
			data: registrationData,
		} );

		yield dispatch(
			'receiveProfile',
			response.user,
		);

		yield dispatch(
			'setAuthToken',
			response.token,
		);

		yield dispatch(
			'registrationSuccess',
			response.user,
		);

		yield dispatch(
			'createSuccessNotice',
			'Erfolgreich registriert.',
			{
				id: REGISTRATION_NOTICE_ID,
				type: 'snackbar',
			},
		);
	} catch ( error ) {
		yield dispatch(
			'createErrorNotice',
			...getNotificationArgumentsForRegistrationFail( { error } ),
		);

		yield dispatch(
			'registrationFailure',
			error,
		);
	}
}

type NoticeOptions = Partial<Pick<Notice, 'id' | 'type' | 'isDismissible' | 'isPersistent' | 'action'>>;

/**
 * Yields action objects used in signalling that a notice is to be created.
 *
 * @param {string}        status                Notice status.Defaults to `info`.
 * @param {string}        content               Notice message.
 * @param {Object}        options               Notice options.
 * @param {string=}       options.id            Identifier for notice.
 *                                              Automatically assigned if not specified.
 * @param {string=}       options.type          UI type of notice.
 *                                              'snackbar' for Snackbar, 'default' for banners.
 * @param {boolean=}      options.isDismissible Whether the notice can be dismissed by user.
 *                                              Defaults to `true`.
 * @param {boolean=}      options.isPersistent  Whether a Snackbar notice should not hide automatically.
 *                                              Defaults to `false`.
 * @param {NoticeAction=} options.action        User action to be presented with notice.
 */
export function* createNotice( status: Notice['status'] = DEFAULT_NOTICE_STATUS, content: Notice['content'], options: NoticeOptions = {} ) {
	const {
		isDismissible = true,
		id = uniqueId( status ),
		type = 'default',
		action = null,
		isPersistent = false,
	} = options;

	yield {
		type: 'CREATE_NOTICE',
		notice: {
			id,
			status,
			content,
			action,
			isDismissible,
			type,
			isPersistent,
		},
	};
}

/**
 * Returns an action object used in signalling that a success notice is to be
 * created.
 */
export function createSuccessNotice( content: Notice['content'], options: NoticeOptions ) {
	return createNotice( 'success', content, options );
}

/**
 * Returns an action object used in signalling that an info notice is to be
 * created.
 */
export function createInfoNotice( content: Notice['content'], options: NoticeOptions ) {
	return createNotice( 'info', content, options );
}

/**
 * Returns an action object used in signalling that an error notice is to be
 * created.
 */
export function createErrorNotice( content: Notice['content'], options: NoticeOptions ) {
	return createNotice( 'error', content, options );
}

/**
 * Returns an action object used in signalling that a warning notice is to be
 * created.
 */
export function createWarningNotice( content: Notice['content'], options: NoticeOptions ) {
	return createNotice( 'warning', content, options );
}

/**
 * Returns an action object used in signalling that a notice is to be removed.
 */
export function removeNotice( id: Notice['id'] ) {
	return {
		type: 'REMOVE_NOTICE',
		id,
	};
}

/**
 * Returns an action object used in signalling that all notices are to be removed.
 */
export function removeAllNotices() {
	return { type: 'REMOVE_ALL_NOTICES' };
}

export function fetchPage(
	pagePath,
	pagePassword,
) {
	return {
		type: 'FETCH_PAGE',
		pagePath,
		pagePassword,
	};
}

export function fetchPageSuccess(
	pagePath,
	pagePassword,
) {
	return {
		type: 'FETCH_PAGE_SUCCESS',
		pagePath,
		pagePassword,
	};
}

export function fetchPageFailure(
	pagePath,
	pagePassword,
	error,
) {
	return {
		type: 'FETCH_PAGE_FAILURE',
		pagePath,
		pagePassword,
		error,
	};
}

export function receivePage(
	pagePath,
	pagePassword,
	page,
) {
	return {
		type: 'RECEIVE_PAGE',
		pagePath,
		pagePassword,
		page,
	};
}

export function fetchProfile() {
	return { type: 'FETCH_PROFILE' };
}

export function fetchProfileSuccess() {
	return { type: 'FETCH_PROFILE_SUCCESS' };
}

export function fetchProfileFailure( error ) {
	return {
		type: 'FETCH_PROFILE_FAILURE',
		error,
	};
}

export function receiveProfile( profile ) {
	return {
		type: 'RECEIVE_PROFILE',
		profile,
	};
}

export function removeProfile() {
	return { type: 'REMOVE_PROFILE' };
}

export function fetchSchools() {
	return { type: 'FETCH_SCHOOLS' };
}

export function fetchSchoolsSuccess() {
	return { type: 'FETCH_SCHOOLS_SUCCESS' };
}

export function fetchSchoolsFailure( error ) {
	return {
		type: 'FETCH_SCHOOLS_FAILURE',
		error,
	};
}

export function receiveSchools( schools ) {
	return {
		type: 'RECEIVE_SCHOOLS',
		schools,
	};
}

export function fetchTeachers( schoolID ) {
	return {
		type: 'FETCH_TEACHERS',
		schoolID,
	};
}

export function fetchTeachersSuccess( schoolID ) {
	return {
		type: 'FETCH_TEACHERS_SUCCESS',
		schoolID,
	};
}

export function fetchTeachersFailure(
	schoolID,
	error,
) {
	return {
		type: 'FETCH_TEACHERS_FAILURE',
		schoolID,
		error,
	};
}

export function receiveTeachers(
	teachers,
	schoolID,
) {
	return {
		type: 'RECEIVE_TEACHERS',
		schoolID,
		teachers,
	};
}

export function fetchDistricts( query ) {
	return {
		type: 'FETCH_DISTRICTS',
		query,
	};
}

export function fetchDistrictsSuccess( query ) {
	return {
		type: 'FETCH_DISTRICTS_SUCCESS',
		query,
	};
}

export function fetchDistrictsFailure(
	query,
	error,
) {
	return {
		type: 'FETCH_DISTRICTS_FAILURE',
		query,
		error,
	};
}

export function receiveDistricts(
	query,
	items,
) {
	return {
		type: 'RECEIVE_DISTRICTS',
		query,
		items,
	};
}

export function fetchEventAudience() {
	return { type: 'FETCH_EVENT_AUDIENCE' };
}

export function fetchEventAudienceSuccess() {
	return { type: 'FETCH_EVENT_AUDIENCE_SUCCESS' };
}

export function fetchEventAudienceFailure( error ) {
	return {
		type: 'FETCH_EVENT_AUDIENCE_FAILURE',
		error,
	};
}

export function receiveEventAudience( audience ) {
	return {
		type: 'RECEIVE_EVENT_AUDIENCE',
		audience,
	};
}

export function fetchEventTopics() {
	return { type: 'FETCH_EVENT_TOPICS' };
}

export function fetchEventTopicsSuccess() {
	return { type: 'FETCH_EVENT_TOPICS_SUCCESS' };
}

export function fetchEventTopicsFailure( error ) {
	return {
		type: 'FETCH_EVENT_TOPICS_FAILURE',
		error,
	};
}

export function receiveEventTopics( topics ) {
	return {
		type: 'RECEIVE_EVENT_TOPICS',
		topics,
	};
}

export function fetchCities() {
	return { type: 'FETCH_CITIES' };
}

export function fetchCitiesSuccess() {
	return { type: 'FETCH_CITIES_SUCCESS' };
}

export function fetchCitiesFailure( error ) {
	return {
		type: 'FETCH_CITIES_FAILURE',
		error,
	};
}

export function receiveCities( cities ) {
	return {
		type: 'RECEIVE_CITIES',
		cities,
	};
}

export function fetchOffices( query ) {
	return {
		type: 'FETCH_OFFICES',
		query,
	};
}

export function fetchOfficesSuccess( query ) {
	return {
		type: 'FETCH_OFFICES_SUCCESS',
		query,
	};
}

export function fetchOfficesFailure(
	query,
	error,
) {
	return {
		type: 'FETCH_OFFICES_FAILURE',
		query,
		error,
	};
}

export function receiveOffices(
	query,
	items,
	count,
	total,
	totalPages,
) {
	return {
		type: 'RECEIVE_OFFICES',
		query,
		items,
		count,
		total,
		totalPages,
	};
}

export function fetchProfessions( query ) {
	return {
		type: 'FETCH_PROFESSIONS',
		query,
	};
}

export function fetchProfessionsSuccess( query ) {
	return {
		type: 'FETCH_PROFESSIONS_SUCCESS',
		query,
	};
}

export function fetchProfessionsFailure(
	query,
	error,
) {
	return {
		type: 'FETCH_PROFESSIONS_FAILURE',
		query,
		error,
	};
}

export function receiveProfessions(
	query,
	items,
	count,
	total,
	totalPages,
) {
	return {
		type: 'RECEIVE_PROFESSIONS',
		query,
		items,
		count,
		total,
		totalPages,
	};
}

export function fetchApprenticeshipProfessions( query ) {
	return {
		type: 'FETCH_APPRENTICESHIP_PROFESSIONS',
		query,
	};
}

export function fetchApprenticeshipProfessionsSuccess( query ) {
	return {
		type: 'FETCH_APPRENTICESHIP_PROFESSIONS_SUCCESS',
		query,
	};
}

export function fetchApprenticeshipProfessionsFailure(
	query,
	error,
) {
	return {
		type: 'FETCH_APPRENTICESHIP_PROFESSIONS_FAILURE',
		query,
		error,
	};
}

export function receiveApprenticeshipProfessions(
	query,
	items,
) {
	return {
		type: 'RECEIVE_APPRENTICESHIP_PROFESSIONS',
		query,
		items,
	};
}

export function fetchApprenticeshipLocations( query ) {
	return {
		type: 'FETCH_APPRENTICESHIP_LOCATIONS',
		query,
	};
}

export function fetchApprenticeshipLocationsSuccess( query ) {
	return {
		type: 'FETCH_APPRENTICESHIP_LOCATIONS_SUCCESS',
		query,
	};
}

export function fetchApprenticeshipLocationsFailure(
	query,
	error,
) {
	return {
		type: 'FETCH_APPRENTICESHIP_LOCATIONS_FAILURE',
		query,
		error,
	};
}

export function receiveApprenticeshipLocations(
	query,
	items,
) {
	return {
		type: 'RECEIVE_APPRENTICESHIP_LOCATIONS',
		query,
		items,
	};
}

export function fetchApprenticeships( query ) {
	return {
		type: 'FETCH_APPRENTICESHIPS',
		query,
	};
}

export function fetchApprenticeshipsSuccess( query ) {
	return {
		type: 'FETCH_APPRENTICESHIPS_SUCCESS',
		query,
	};
}

export function fetchApprenticeshipsFailure(
	query,
	error,
) {
	return {
		type: 'FETCH_APPRENTICESHIPS_FAILURE',
		query,
		error,
	};
}

export function receiveApprenticeships(
	query,
	items,
) {
	return {
		type: 'RECEIVE_APPRENTICESHIPS',
		query,
		items,
	};
}

/**
 * ApprenticeshipSlots actions
 */

export function createApprenticeshipSlotRequest( organizationId, apprenticeshipId ) {
	return {
		type: 'CREATE_APPRENTICESHIP_SLOT_REQUEST',
		organizationId,
		apprenticeshipId,
	};
}

export function createApprenticeshipSlotSuccess( organizationId, apprenticeshipId, slot ) {
	return {
		type: 'CREATE_APPRENTICESHIP_SLOT_SUCCESS',
		organizationId,
		apprenticeshipId,
		slot,
	};
}

export function createApprenticeshipSlotFailure( organizationId, apprenticeshipId, error ) {
	return {
		type: 'CREATE_APPRENTICESHIP_SLOT_FAILURE',
		organizationId,
		apprenticeshipId,
		error,
	};
}

/**
 * This function manages the ApprenticeshipSlot submission to the server
 *
 * @param {AppStateApprenticeshipSlot} data             The query object that will be sent to the server
 * @param {number}                     organizationId   The id of the organization of the apprenticeship
 * @param {number}                     apprenticeshipId The id of the apprenticeship that contains the slot
 */
export function* createApprenticeshipSlot( data: AppStateApprenticeshipSlot, organizationId: number, apprenticeshipId: number ) {
	yield dispatch( 'createApprenticeshipSlotRequest', organizationId, apprenticeshipId );

	yield dispatch(
		'createSuccessNotice',
		'Schnupperslot wird erstellt …',
		{
			id: APPRENTICESHIP_SLOT_NOTICE_ID,
			type: 'snackbar',
		},
	);

	try {
		const slot = yield apiFetch( {
			path: `/wp-json/biz/v1/apprenticeships/${ apprenticeshipId }/slots`,
			method: 'POST',
			data,
		} );

		yield dispatch(
			'createApprenticeshipSlotSuccess',
			organizationId,
			apprenticeshipId,
			slot,
		);

		yield dispatch(
			'createSuccessNotice',
			'Schnupperslot erstellt.',
			{
				id: APPRENTICESHIP_SLOT_NOTICE_ID,
				type: 'snackbar',
			},
		);
	} catch ( error ) {
		yield dispatch(
			'createErrorNotice',
			...getNotificationArgumentsForCreateApprenticeshipSlotFail( { error } ),
		);

		yield dispatch(
			'createApprenticeshipSlotFailure',
			organizationId,
			apprenticeshipId,
			error,
		);
	}
}

export function updateApprenticeshipSlotRequest(
	organizationId,
	apprenticeshipId,
	slotId,
) {
	return {
		type: 'UPDATE_APPRENTICESHIP_SLOT_REQUEST',
		organizationId,
		apprenticeshipId,
		slotId,
	};
}

export function updateApprenticeshipSlotSuccess(
	organizationId,
	apprenticeshipId,
	slotId,
	slot,
) {
	return {
		type: 'UPDATE_APPRENTICESHIP_SLOT_SUCCESS',
		organizationId,
		apprenticeshipId,
		slotId,
		slot,
	};
}

export function updateApprenticeshipSlotFailure(
	organizationId,
	apprenticeshipId,
	slotId,
	error,
) {
	return {
		type: 'UPDATE_APPRENTICESHIP_SLOT_FAILURE',
		organizationId,
		apprenticeshipId,
		slotId,
		error,
	};
}

/**
 * This function manages the ApprenticeshipSlot submission to the server
 *
 * @param {AppStateApprenticeshipSlot} data             The query object that will be sent to the server
 * @param {number}                     organizationId   The id of the organization of the apprenticeship
 * @param {number}                     apprenticeshipId The id of the apprenticeship that contains the slot
 * @param {number}                     slotId           The id of the slot that should be deleted
 */
export function* updateApprenticeshipSlot( data: AppStateApprenticeshipSlot, organizationId: number, apprenticeshipId: number, slotId: number ) {
	yield dispatch( 'updateApprenticeshipSlotRequest', organizationId, apprenticeshipId, slotId );

	yield dispatch(
		'createSuccessNotice',
		'Schnupperslot wird aktualisiert …',
		{
			id: APPRENTICESHIP_SLOT_NOTICE_ID,
			type: 'snackbar',
		},
	);

	try {
		const slot = yield apiFetch( {
			path: `/wp-json/biz/v1/apprenticeships/${ apprenticeshipId }/slots/${ slotId }`,
			method: 'PUT',
			data,
		} );

		yield dispatch(
			'updateApprenticeshipSlotSuccess',
			organizationId,
			apprenticeshipId,
			slotId,
			slot,
		);

		yield dispatch(
			'createSuccessNotice',
			'Schnupperslot aktualisiert.',
			{
				id: APPRENTICESHIP_SLOT_NOTICE_ID,
				type: 'snackbar',
			},
		);
	} catch ( error ) {
		yield dispatch(
			'createErrorNotice',
			...getNotificationArgumentsForUpdateApprenticeshipSlotFail( { error } ),
		);

		yield dispatch(
			'updateApprenticeshipSlotFailure',
			organizationId,
			apprenticeshipId,
			slotId,
			error,
		);
	}
}

export function deleteApprenticeshipSlotRequest( organizationId, apprenticeshipId, slotId ) {
	return {
		type: 'DELETE_APPRENTICESHIP_SLOT_REQUEST',
		organizationId,
		apprenticeshipId,
		slotId,
	};
}

export function deleteApprenticeshipSlotSuccess( organizationId, apprenticeshipId, slotId, response ) {
	return {
		type: 'DELETE_APPRENTICESHIP_SLOT_SUCCESS',
		organizationId,
		apprenticeshipId,
		slotId,
		response,
	};
}

export function deleteApprenticeshipSlotFailure( organizationId, apprenticeshipId, slotId, error ) {
	return {
		type: 'DELETE_APPRENTICESHIP_SLOT_FAILURE',
		organizationId,
		apprenticeshipId,
		slotId,
		error,
	};
}

/**
 * This function manages the ApprenticeshipSlot submission to the server
 *
 * @param {number} organizationId   The id of the organization of the apprenticeship
 * @param {number} apprenticeshipId The id of the apprenticeship that contains the slot
 * @param {number} slotId           The id of the slot that should be deleted
 */
export function* deleteApprenticeshipSlot( organizationId: number, apprenticeshipId: number, slotId: number ) {
	yield dispatch( 'deleteApprenticeshipSlotRequest', organizationId, apprenticeshipId, slotId );

	yield dispatch(
		'createSuccessNotice',
		'Schnupperslot wird gelöscht …',
		{
			id: APPRENTICESHIP_SLOT_NOTICE_ID,
			type: 'snackbar',
		},
	);

	try {
		const response = yield apiFetch( {
			path: `/wp-json/biz/v1/apprenticeships/${ apprenticeshipId }/slots/${ slotId }`,
			method: 'DELETE',
		} );

		yield dispatch(
			'deleteApprenticeshipSlotSuccess',
			organizationId,
			apprenticeshipId,
			slotId,
			response,
		);

		yield dispatch(
			'createSuccessNotice',
			'Schnupperslot gelöscht.',
			{
				id: APPRENTICESHIP_SLOT_NOTICE_ID,
				type: 'snackbar',
			},
		);
	} catch ( error ) {
		yield dispatch(
			'createErrorNotice',
			...getNotificationArgumentsForDeleteApprenticeshipSlotFail( { error } ),
		);

		yield dispatch(
			'deleteApprenticeshipSlotFailure',
			error,
		);
	}
}

/**
 * ApprenticeshipRequest actions
 */

export function createApprenticeshipRequestRequest( organizationId, apprenticeshipId, slotId ) {
	return {
		type: 'CREATE_APPRENTICESHIP_REQUEST_REQUEST',
		organizationId,
		apprenticeshipId,
		slotId,
	};
}

export function createApprenticeshipRequestSuccess( organizationId, apprenticeshipId, slotId, slot ) {
	return {
		type: 'CREATE_APPRENTICESHIP_REQUEST_SUCCESS',
		organizationId,
		apprenticeshipId,
		slotId,
		slot,
	};
}

export function createApprenticeshipRequestFailure( organizationId, apprenticeshipId, slotId, error ) {
	return {
		type: 'CREATE_APPRENTICESHIP_REQUEST_FAILURE',
		organizationId,
		apprenticeshipId,
		slotId,
		error,
	};
}

/**
 * This function manages the ApprenticeshipRequest submission to the server
 *
 * @param {AppStateApprenticeshipRequest} data             The query object that will be sent to the server
 * @param {number}                        organizationId   The id of the organization of the apprenticeship
 * @param {number}                        apprenticeshipId The id of the apprenticeship that contains the slot
 * @param {number}                        slotId           The id of the slot that contains the requests
 */
export function* createApprenticeshipRequest( data: AppStateApprenticeshipRequest, organizationId: number, apprenticeshipId: number, slotId: number ) {
	yield dispatch( 'createApprenticeshipRequestRequest', organizationId, apprenticeshipId, slotId );

	const slotData = { requests: [ { ...data } ] };

	try {
		const slot = yield apiFetch( {
			path: `/wp-json/biz/v1/apprenticeships/${ apprenticeshipId }/slots/${ slotId }`,
			method: 'POST',
			data: slotData,
		} );

		yield dispatch(
			'createApprenticeshipRequestSuccess',
			organizationId,
			apprenticeshipId,
			slotId,
			slot,
		);
	} catch ( error ) {
		yield dispatch(
			'createErrorNotice',
			// TODO: Create a new notification
			...getNotificationArgumentsForCreateApprenticeshipSlotFail( { error } ),
		);

		yield dispatch(
			'createApprenticeshipRequestFailure',
			organizationId,
			apprenticeshipId,
			slotId,
			error,
		);
	}
}

/**
 * ApprenticeshipRequest actions
 */

export function updateApprenticeshipRequestRequest(
	organizationId,
	apprenticeshipId,
	slotId,
	requestId,
) {
	return {
		type: 'UPDATE_APPRENTICESHIP_REQUEST_REQUEST',
		organizationId,
		apprenticeshipId,
		slotId,
		requestId,
	};
}

export function updateApprenticeshipRequestSuccess(
	organizationId,
	apprenticeshipId,
	slotId,
	requestId,
	slot,
) {
	return {
		type: 'UPDATE_APPRENTICESHIP_REQUEST_SUCCESS',
		organizationId,
		apprenticeshipId,
		slotId,
		requestId,
		slot,
	};
}

export function updateApprenticeshipRequestFailure(
	organizationId,
	apprenticeshipId,
	slotId,
	requestId,
	error,
) {
	return {
		type: 'UPDATE_APPRENTICESHIP_REQUEST_FAILURE',
		organizationId,
		apprenticeshipId,
		slotId,
		requestId,
		error,
	};
}

/**
 * This function manages the ApprenticeshipRequest submission to the server
 *
 * @param {AppStateApprenticeshipRequest} data             The query object that will be sent to the server
 * @param {number}                        organizationId   The id of the organization of the apprenticeship
 * @param {number}                        apprenticeshipId The id of the apprenticeship that contains the slot
 * @param {number}                        slotId           The id of the slot that contains the requests
 * @param {number}                        requestId        The id of the request to update
 */
export function* updateApprenticeshipRequest( data: AppStateApprenticeshipRequest, organizationId: number, apprenticeshipId: number, slotId: number, requestId: number ) {
	yield dispatch( 'updateApprenticeshipRequestRequest', organizationId, apprenticeshipId, slotId, requestId );

	const slotData = {
		requests: [ {
			id: requestId,
			...data,
		} ],
	};

	try {
		const slot = yield apiFetch( {
			path: `/wp-json/biz/v1/apprenticeships/${ apprenticeshipId }/slots/${ slotId }`,
			method: 'POST',
			data: slotData,
		} );

		yield dispatch(
			'updateApprenticeshipRequestSuccess',
			organizationId,
			apprenticeshipId,
			slotId,
			requestId,
			slot,
		);
	} catch ( error ) {
		yield dispatch(
			'createErrorNotice',
			// TODO: Create a new notification
			...getNotificationArgumentsForCreateApprenticeshipSlotFail( { error } ),
		);

		yield dispatch(
			'updateApprenticeshipRequestFailure',
			organizationId,
			apprenticeshipId,
			slotId,
			requestId,
			error,
		);
	}
}

export function fetchProfessionFields() {
	return { type: 'FETCH_PROFESSION_FIELDS' };
}

export function fetchProfessionFieldsSuccess() {
	return { type: 'FETCH_PROFESSION_FIELDS_SUCCESS' };
}

export function fetchProfessionFieldsFailure( error ) {
	return {
		type: 'FETCH_PROFESSION_FIELDS_FAILURE',
		error,
	};
}

export function receiveProfessionFields( fields ) {
	return {
		type: 'RECEIVE_PROFESSION_FIELDS',
		fields,
	};
}

export function fetchProfessionDurations() {
	return { type: 'FETCH_PROFESSION_DURATIONS' };
}

export function fetchProfessionDurationsSuccess() {
	return { type: 'FETCH_PROFESSION_DURATIONS_SUCCESS' };
}

export function fetchProfessionDurationsFailure( error ) {
	return {
		type: 'FETCH_PROFESSION_DURATIONS_FAILURE',
		error,
	};
}

export function receiveProfessionDurations( durations ) {
	return {
		type: 'RECEIVE_PROFESSION_DURATIONS',
		durations,
	};
}

export function fetchProfessionQualifications() {
	return { type: 'FETCH_PROFESSION_QUALIFICATIONS' };
}

export function fetchProfessionQualificationsSuccess() {
	return { type: 'FETCH_PROFESSION_QUALIFICATIONS_SUCCESS' };
}

export function fetchProfessionQualificationsFailure( error ) {
	return {
		type: 'FETCH_PROFESSION_QUALIFICATIONS_FAILURE',
		error,
	};
}

export function receiveProfessionQualifications( qualifications ) {
	return {
		type: 'RECEIVE_PROFESSION_QUALIFICATIONS',
		qualifications,
	};
}

export function fetchProfessionInterests() {
	return { type: 'FETCH_PROFESSION_INTERESTS' };
}

export function fetchProfessionInterestsSuccess() {
	return { type: 'FETCH_PROFESSION_INTERESTS_SUCCESS' };
}

export function fetchProfessionInterestsFailure( error ) {
	return {
		type: 'FETCH_PROFESSION_INTERESTS_FAILURE',
		error,
	};
}

export function receiveProfessionInterests( interests ) {
	return {
		type: 'RECEIVE_PROFESSION_INTERESTS',
		interests,
	};
}

export function fetchProfessionSectors() {
	return { type: 'FETCH_PROFESSION_SECTORS' };
}

export function fetchProfessionSectorsSuccess() {
	return { type: 'FETCH_PROFESSION_SECTORS_SUCCESS' };
}

export function fetchProfessionSectorsFailure( error ) {
	return {
		type: 'FETCH_PROFESSION_SECTORS_FAILURE',
		error,
	};
}

export function receiveProfessionSectors( sectors ) {
	return {
		type: 'RECEIVE_PROFESSION_SECTORS',
		sectors,
	};
}

export function fetchEvents( query ) {
	return {
		type: 'FETCH_EVENTS',
		query,
	};
}

export function fetchEventsSuccess( query ) {
	return {
		type: 'FETCH_EVENTS_SUCCESS',
		query,
	};
}

export function fetchEventsFailure(
	query,
	error,
) {
	return {
		type: 'FETCH_EVENTS_FAILURE',
		query,
		error,
	};
}

export function receiveEvents(
	query,
	items,
	count,
	total,
	totalPages,
) {
	return {
		type: 'RECEIVE_EVENTS',
		query,
		items,
		count,
		total,
		totalPages,
	};
}

export function createEventRequest( data ) {
	return {
		type: 'CREATE_EVENT_REQUEST',
		data,
	};
}

export function createEventSuccess( event ) {
	return {
		type: 'CREATE_EVENT_SUCCESS',
		event,
	};
}

export function createEventFailure( error ) {
	return {
		type: 'CREATE_EVENT_FAILURE',
		error,
	};
}

/**
 * This function manages the event creation
 *
 * @param {Object} data The data object that will be sent to the server
 */
export function* createEvent( data: AppStateEventItem ) {
	yield dispatch( 'createEventRequest', data );

	try {
		const event = yield apiFetch( {
			path: '/wp-json/biz/v1/events/',
			method: 'POST',
			data,
		} );

		yield dispatch(
			'createEventSuccess',
			event,
		);
	} catch ( error ) {
		yield dispatch(
			'createErrorNotice',
			// TODO: Change notice
			...getNotificationArgumentsForEventFail( { error } ),
		);

		yield dispatch(
			'createEventFailure',
			error,
		);
	}
}

export function deleteEventRequest( eventId ) {
	return {
		type: 'DELETE_EVENT_REQUEST',
		eventId,
	};
}

export function deleteEventSuccess( eventId, response ) {
	return {
		type: 'DELETE_EVENT_SUCCESS',
		eventId,
		response,
	};
}

export function deleteEventFailure( eventId, error ) {
	return {
		type: 'DELETE_EVENT_FAILURE',
		eventId,
		error,
	};
}

/**
 * This function manages the event deletion
 *
 * @param {number} eventId The id of the event
 */
export function* deleteEvent( eventId: number ) {
	yield dispatch( 'deleteEventRequest', eventId );

	yield dispatch(
		'createSuccessNotice',
		'Veranstaltung wird gelöscht …',
		{
			id: EVENT_NOTICE_ID,
			type: 'snackbar',
		},
	);

	try {
		const response = yield apiFetch( {
			path: `/wp-json/biz/v1/events/${ eventId }`,
			method: 'DELETE',
		} );

		yield dispatch(
			'deleteEventSuccess',
			eventId,
			response,
		);

		yield dispatch(
			'createSuccessNotice',
			'Veranstaltung gelöscht.',
			{
				id: EVENT_NOTICE_ID,
				type: 'snackbar',
			},
		);
	} catch ( error ) {
		yield dispatch(
			'createErrorNotice',
			// TODO: Change notice
			...getNotificationArgumentsForEventFail( { error } ),
		);

		yield dispatch(
			'deleteEventFailure',
			error,
		);
	}
}

export function updateEventRequest( eventId, data ) {
	return {
		type: 'UPDATE_EVENT_REQUEST',
		eventId,
		data,
	};
}

export function updateEventSuccess( eventId, event ) {
	return {
		type: 'UPDATE_EVENT_SUCCESS',
		eventId,
		event,
	};
}

export function updateEventFailure( eventId, error ) {
	return {
		type: 'UPDATE_EVENT_FAILURE',
		eventId,
		error,
	};
}

/**
 * This function manages the event submission to the server
 *
 * @param {Object} data    The data object that will be sent to the server
 * @param {number} eventId The id of the event
 */
export function* updateEvent( data: AppStateEventItem, eventId: number ) {
	yield dispatch( 'updateEventRequest', eventId, data );

	try {
		const event = yield apiFetch( {
			path: `/wp-json/biz/v1/events/${ eventId }`,
			method: 'PUT',
			data,
		} );

		yield dispatch(
			'updateEventSuccess',
			eventId,
			event,
		);
	} catch ( error ) {
		yield dispatch(
			'createErrorNotice',
			// TODO: Change notice
			...getNotificationArgumentsForEventFail( { error } ),
		);

		yield dispatch(
			'updateEventFailure',
			eventId,
			error,
		);
	}
}

export function fetchSearchResults( query ) {
	return {
		type: 'FETCH_SEARCH_RESULTS',
		query,
	};
}

export function fetchSearchResultsSuccess( query ) {
	return {
		type: 'FETCH_SEARCH_RESULTS_SUCCESS',
		query,
	};
}

export function fetchSearchResultsFailure(
	query,
	error,
) {
	return {
		type: 'FETCH_SEARCH_RESULTS_FAILURE',
		query,
		error,
	};
}

export function receiveSearchResults(
	query,
	items,
	count,
	total,
	totalPages,
) {
	return {
		type: 'RECEIVE_SEARCH_RESULTS',
		query,
		items,
		count,
		total,
		totalPages,
	};
}

/**
 * Form actions
 */

export function fetchFormRequest( data ) {
	return {
		type: 'FETCH_FORM_REQUEST',
		data,
	};
}

export function fetchFormSuccess( response ) {
	return {
		type: 'FETCH_FORM_SUCCESS',
		response,
	};
}

export function fetchFormFailure( error ) {
	return {
		type: 'FETCH_FORM_FAILURE',
		error,
	};
}

export function resetFormData() {
	return { type: 'RESET_FORM_DATA' };
}

/**
 * This function manages the form submission to the server
 *
 * @param {Object} data    The data object that will be sent to the server
 * @param {string} pathkey The path that will be used to fetch the REST API. Example: `contact` or `delete-profile`
 */
export function* fetchForm( data, pathkey ) {
	yield dispatch( 'fetchFormRequest', data );

	try {
		const response = yield apiFetch( {
			path: '/wp-json/biz/v1/form-entries/' + pathkey,
			method: 'POST',
			data,
		} );

		yield dispatch(
			'fetchFormSuccess',
			response,
		);

		yield dispatch( 'invalidateCurrentUserActivities' );
	} catch ( error ) {
		yield dispatch(
			'createErrorNotice',
			...getNotificationArgumentsForFormFail( { error } ),
		);

		yield dispatch(
			'fetchFormFailure',
			error,
		);
	}
}

export function requestUserConnectionInit( data ) {
	return {
		type: 'REQUEST_USER_CONNECTION_INIT',
		data,
	};
}

export function requestUserConnectionSuccess( connection ) {
	return {
		type: 'REQUEST_USER_CONNECTION_SUCCESS',
		connection,
	};
}

export function requestUserConnectionFailure( error ) {
	return {
		type: 'REQUEST_USER_CONNECTION_FAILURE',
		error,
	};
}

/**
 * Inits request for a new user connection.
 *
 * @param {Object} data The data object that will be sent to the server
 */
export function* requestUserConnection( data ) {
	yield dispatch( 'requestUserConnectionInit', data );

	try {
		const connection = yield apiFetch( {
			path: '/wp-json/biz/v1/user-connections',
			method: 'POST',
			data,
		} );

		yield dispatch(
			'createSuccessNotice',
			'Freigabe erfolgt.',
			{
				id: USER_CONNECTION_NOTICE_ID,
				type: 'snackbar',
			},
		);

		yield dispatch(
			'requestUserConnectionSuccess',
			connection,
		);

		yield dispatch( 'invalidateCurrentUserActivities' );
	} catch ( error ) {
		yield dispatch(
			'createErrorNotice',
			...getNotificationArgumentsForRequestUserConnectionFail( { error } ),
		);

		yield dispatch(
			'requestUserConnectionFailure',
			error,
		);
	}
}

export function deleteUserConnectionInit( id ) {
	return {
		type: 'DELETE_USER_CONNECTION_INIT',
		id,
	};
}

export function deleteUserConnectionSuccess( id ) {
	return {
		type: 'DELETE_USER_CONNECTION_SUCCESS',
		id,
	};
}

export function deleteUserConnectionFailure( error ) {
	return {
		type: 'DELETE_USER_CONNECTION_FAILURE',
		error,
	};
}

/**
 * Inits request for deleting user connection.
 *
 * @param {integer} id ID of the connection to be deleted.
 */
export function* deleteUserConnection( id ) {
	yield dispatch( 'deleteUserConnectionInit', id );

	try {
		const response = yield apiFetch( {
			path: '/wp-json/biz/v1/user-connections/' + id,
			method: 'DELETE',
		} );

		yield dispatch(
			'createSuccessNotice',
			'Freigabe gelöscht.',
			{
				id: USER_CONNECTION_NOTICE_ID,
				type: 'snackbar',
			},
		);

		yield dispatch(
			'deleteUserConnectionSuccess',
			id,
			response,
		);

		yield dispatch( 'invalidateCurrentUserActivities' );
	} catch ( error ) {
		yield dispatch(
			'createErrorNotice',
			...getNotificationArgumentsForDeleteUserConnectionFail( { error } ),
		);

		yield dispatch(
			'deleteUserConnectionFailure',
			error,
		);
	}
}

export function updateUserConnectionInit(
	id,
	data,
) {
	return {
		type: 'UPDATE_USER_CONNECTION_INIT',
		id,
		data,
	};
}

export function updateUserConnectionSuccess(
	id,
	changes,
) {
	return {
		type: 'UPDATE_USER_CONNECTION_SUCCESS',
		id,
		changes,
	};
}

export function updateUserConnectionFailure( error ) {
	return {
		type: 'UPDATE_USER_CONNECTION_FAILURE',
		error,
	};
}

/**
 * Inits request for updating user connection.
 *
 * @param {integer} id   ID of the connection to be deleted.
 * @param {object}  data Data to update.
 */
export function* updateUserConnection( id, data ) {
	yield dispatch( 'updateUserConnectionInit', id, data );

	try {
		const response = yield apiFetch( {
			path: '/wp-json/biz/v1/user-connections/' + id,
			method: 'POST',
			data,
		} );

		yield dispatch(
			'createSuccessNotice',
			'Freigabe aktualisiert.',
			{
				id: USER_CONNECTION_NOTICE_ID,
				type: 'snackbar',
			},
		);

		yield dispatch(
			'updateUserConnectionSuccess',
			id,
			response,
		);

		yield dispatch( 'invalidateCurrentUserActivities' );
	} catch ( error ) {
		yield dispatch(
			'createErrorNotice',
			...getNotificationArgumentsForUpdateUserConnectionFail( { error } ),
		);

		yield dispatch(
			'updateUserConnectionFailure',
			error,
		);
	}
}

export function invalidateUserConnections() {
	return { type: 'INVALIDATE_USER_CONNECTIONS' };
}

export function fetchUserConnections() {
	return { type: 'FETCH_USER_CONNECTIONS' };
}

export function fetchUserConnectionsSuccess() {
	return { type: 'FETCH_USER_CONNECTIONS_SUCCESS' };
}

export function fetchUserConnectionsFailure( error ) {
	return {
		type: 'FETCH_USER_CONNECTIONS_FAILURE',
		error,
	};
}

export function receiveUserConnections(
	connections,
	invalidateCache = false,
) {
	return {
		type: 'RECEIVE_USER_CONNECTIONS',
		connections,
		invalidateCache,
	};
}

export function fetchUser( id ) {
	return {
		type: 'FETCH_USER',
		id,
	};
}

export function fetchUserSuccess( id ) {
	return {
		type: 'FETCH_USER_SUCCESS',
		id,
	};
}

export function fetchUserFailure(
	id,
	error,
) {
	return {
		type: 'FETCH_USER_FAILURE',
		id,
		error,
	};
}

export function invalidateUsers() {
	return { type: 'INVALIDATE_USERS' };
}

export function receiveUser( user ) {
	return {
		type: 'RECEIVE_USER',
		user,
	};
}

export function invalidateCurrentUserRememberedItems() {
	return { type: 'INVALIDATE_CURRENT_USER_REMEMBERED_ITEMS' };
}

export function fetchCurrentUserRememberedItems() {
	return { type: 'FETCH_CURRENT_USER_REMEMBERED_ITEMS' };
}

export function fetchCurrentUserRememberedItemsSuccess() {
	return { type: 'FETCH_CURRENT_USER_REMEMBERED_ITEMS_SUCCESS' };
}

export function fetchCurrentUserRememberedItemsFailure( error ) {
	return {
		type: 'FETCH_CURRENT_USER_REMEMBERED_ITEMS_FAILURE',
		error,
	};
}

export function receiveCurrentUserRememberedItems( items ) {
	return {
		type: 'RECEIVE_CURRENT_USER_REMEMBERED_ITEMS',
		items,
	};
}

export function addCurrentUserRememberedItemInit( id ) {
	return {
		type: 'ADD_CURRENT_USER_REMEMBERED_ITEM_INIT',
		id,
	};
}

export function addCurrentUserRememberedItemSuccess( item ) {
	return {
		type: 'ADD_CURRENT_USER_REMEMBERED_ITEM_SUCCESS',
		item,
	};
}

export function addCurrentUserRememberedItemFailure( error ) {
	return {
		type: 'ADD_CURRENT_USER_REMEMBERED_ITEM_FAILURE',
		error,
	};
}

/**
 * Inits request for a new user remembered item.
 *
 * @param {number} id ID of the item to be remembered.
 */
export function* addCurrentUserRememberedItem( id: number ) {
	yield dispatch( 'addCurrentUserRememberedItemInit', id );

	try {
		const item = yield apiFetch( {
			path: '/wp-json/biz/v1/users/me/remembered',
			method: 'POST',
			data: { id },
		} );

		yield dispatch(
			'createSuccessNotice',
			'Erfolgreich gemerkt.',
			{
				id: CURRENT_USER_REMEMBERED_ITEM_NOTICE_ID,
				type: 'snackbar',
			},
		);

		yield dispatch(
			'addCurrentUserRememberedItemSuccess',
			item,
		);

		yield dispatch( 'invalidateCurrentUserActivities' );
	} catch ( error ) {
		yield dispatch(
			'createErrorNotice',
			...getNotificationArgumentsForAddUserRememberedItemFail( { error } ),
		);

		yield dispatch(
			'addCurrentUserRememberedItemFailure',
			error,
		);
	}
}

export function deleteCurrentUserRememberedItemInit( id ) {
	return {
		type: 'DELETE_CURRENT_USER_REMEMBERED_ITEM_INIT',
		id,
	};
}

export function deleteCurrentUserRememberedItemSuccess( id ) {
	return {
		type: 'DELETE_CURRENT_USER_REMEMBERED_ITEM_SUCCESS',
		id,
	};
}

export function deleteCurrentUserRememberedItemFailure( error ) {
	return {
		type: 'DELETE_CURRENT_USER_REMEMBERED_ITEM_FAILURE',
		error,
	};
}

/**
 * Inits request for deleting a remembered item.
 *
 * @param {number} id ID of the connection to be deleted.
 */
export function* deleteCurrentUserRememberedItem( id: number ) {
	yield dispatch( 'deleteCurrentUserRememberedItemInit', id );

	try {
		const response = yield apiFetch( {
			path: '/wp-json/biz/v1/users/me/remembered/' + id,
			method: 'DELETE',
		} );

		yield dispatch(
			'createSuccessNotice',
			'Gemerktes gelöscht.',
			{
				id: CURRENT_USER_REMEMBERED_ITEM_NOTICE_ID,
				type: 'snackbar',
			},
		);

		yield dispatch(
			'deleteCurrentUserRememberedItemSuccess',
			id,
			response,
		);

		yield dispatch( 'invalidateCurrentUserActivities' );
	} catch ( error ) {
		yield dispatch(
			'createErrorNotice',
			...getNotificationArgumentsForDeleteUserRememberedItemFail( { error } ),
		);

		yield dispatch(
			'deleteCurrentUserRememberedItemFailure',
			error,
		);
	}
}

export function fetchLanguages() {
	return { type: 'FETCH_LANGUAGES' };
}

export function fetchLanguagesSuccess() {
	return { type: 'FETCH_LANGUAGES_SUCCESS' };
}

export function fetchLanguagesFailure( error ) {
	return {
		type: 'FETCH_LANGUAGES_FAILURE',
		error,
	};
}

export function receiveLanguages( languages ) {
	return {
		type: 'RECEIVE_LANGUAGES',
		languages,
	};
}

export function fetchNationalities() {
	return { type: 'FETCH_NATIONALITIES' };
}

export function fetchNationalitiesSuccess() {
	return { type: 'FETCH_NATIONALITIES_SUCCESS' };
}

export function fetchNationalitiesFailure( error ) {
	return {
		type: 'FETCH_NATIONALITIES_FAILURE',
		error,
	};
}

export function receiveNationalities( nationalities ) {
	return {
		type: 'RECEIVE_NATIONALITIES',
		nationalities,
	};
}

export function invalidateCurrentUserActivities() {
	return { type: 'INVALIDATE_CURRENT_USER_ACTIVITIES' };
}

export function fetchCurrentUserActivities() {
	return { type: 'FETCH_CURRENT_USER_ACTIVITIES' };
}

export function fetchCurrentUserActivitiesSuccess() {
	return { type: 'FETCH_CURRENT_USER_ACTIVITIES_SUCCESS' };
}

export function fetchCurrentUserActivitiesFailure( error ) {
	return {
		type: 'FETCH_CURRENT_USER_ACTIVITIES_FAILURE',
		error,
	};
}

export function receiveCurrentUserActivities( activities ) {
	return {
		type: 'RECEIVE_CURRENT_USER_ACTIVITIES',
		activities,
	};
}

export function invalidateUserActivities() {
	return { type: 'INVALIDATE_USER_ACTIVITIES' };
}

export function fetchUserActivities( id ) {
	return {
		type: 'FETCH_USER_ACTIVITIES',
		id,
	};
}

export function fetchUserActivitiesSuccess( id ) {
	return {
		type: 'FETCH_USER_ACTIVITIES_SUCCESS',
		id,
	};
}

export function fetchUserActivitiesFailure(
	id,
	error,
) {
	return {
		type: 'FETCH_USER_ACTIVITIES_FAILURE',
		id,
		error,
	};
}

export function receiveUserActivities(
	id,
	activities,
) {
	return {
		type: 'RECEIVE_USER_ACTIVITIES',
		id,
		activities,
	};
}

export function invalidateUserRememberedItems() {
	return { type: 'INVALIDATE_USER_REMEMBERED_ITEMS' };
}

export function fetchUserRememberedItems( id ) {
	return {
		type: 'FETCH_USER_REMEMBERED_ITEMS',
		id,
	};
}

export function fetchUserRememberedItemsSuccess( id ) {
	return {
		type: 'FETCH_USER_REMEMBERED_ITEMS_SUCCESS',
		id,
	};
}

export function fetchUserRememberedItemsFailure(
	id,
	error,
) {
	return {
		type: 'FETCH_USER_REMEMBERED_ITEMS_FAILURE',
		id,
		error,
	};
}

export function receiveUserRememberedItems(
	id,
	items,
) {
	return {
		type: 'RECEIVE_USER_REMEMBERED_ITEMS',
		id,
		items,
	};
}

export function fetchNews() {
	return { type: 'FETCH_NEWS' };
}

export function fetchNewsSuccess() {
	return { type: 'FETCH_NEWS_SUCCESS' };
}

export function fetchNewsFailure( error ) {
	return {
		type: 'FETCH_NEWS_FAILURE',
		error,
	};
}

export function fetchOrganizations( query ) {
	return {
		type: 'FETCH_ORGANIZATIONS',
		query,
	};
}

export function fetchOrganizationsSuccess( query ) {
	return {
		type: 'FETCH_ORGANIZATIONS_SUCCESS',
		query,
	};
}

export function fetchOrganizationsFailure(
	query,
	error,
) {
	return {
		type: 'FETCH_ORGANIZATIONS_FAILURE',
		query,
		error,
	};
}

export function receiveNews( items ) {
	return {
		type: 'RECEIVE_NEWS',
		items,
	};
}

export function receiveOrganizations(
	query,
	items,
	count,
	total,
	totalPages,
) {
	return {
		type: 'RECEIVE_ORGANIZATIONS',
		query,
		items,
		count,
		total,
		totalPages,
	};
}

export function updateOrganizationRequest(
	id,
	data,
) {
	return {
		type: 'UPDATE_ORGANIZATION_REQUEST',
		id,
		data,
	};
}

export function updateOrganizationSuccess(
	id,
	organization,
) {
	return {
		type: 'UPDATE_ORGANIZATION_SUCCESS',
		id,
		organization,
	};
}

export function updateOrganizationFailure( error, organization ) {
	return {
		type: 'UPDATE_ORGANIZATION_FAILURE',
		error,
		organization,
	};
}

export function receiveOrganization(
	id,
	organization,
) {
	return {
		type: 'RECEIVE_ORGANIZATION',
		id,
		organization,
	};
}

export function* updateOrganization( id: number, data ) {
	yield dispatch( 'updateOrganizationRequest', id, data );

	yield dispatch(
		'createSuccessNotice',
		'Firma wird aktualisiert …',
		{
			id: ORGANIZATION_UPDATE_NOTICE_ID,
			type: 'snackbar',
		},
	);

	try {
		const response = yield apiFetch( {
			path: `/wp-json/biz/v1/organizations/${ id }`,
			method: 'POST',
			data,
		} );

		yield dispatch(
			'receiveOrganization',
			id,
			response,
		);

		yield dispatch(
			'createSuccessNotice',
			'Firma aktualisiert.',
			{
				id: ORGANIZATION_UPDATE_NOTICE_ID,
				type: 'snackbar',
			},
		);

		yield dispatch(
			'updateOrganizationSuccess',
			id,
			response,
		);
	} catch ( error ) {
		yield dispatch(
			'createErrorNotice',
			...getNotificationArgumentsForUpdateOrganizationFail( { error } ),
		);

		yield dispatch(
			'updateOrganizationFailure',
			error,
			data,
		);
	}
}
