/**
 * External dependencies
 */
import React, {
	createContext,
	useState,
	useEffect,
} from 'react';
import usePrevious from '../../hooks/usePrevious';

export const HeaderContext = createContext( {
	isMenuOpen: false,
	openMenu: () => {},
	closeMenu: () => {},
	isSearchFormOpen: false,
	openSearchForm: () => {},
	closeSearchForm: () => {},
} );

export function HeaderProvider( { children } ) {
	const [ isMenuOpen, setMenuIsOpen ] = useState( false );
	const [ isSearchFormOpen, setSearchFormIsOpen ] = useState( false );

	const wasMenuOpen = usePrevious( isMenuOpen );
	const wasSearchFormOpen = usePrevious( isSearchFormOpen );

	const openMenu = () => {
		setMenuIsOpen( true );
	};

	const closeMenu = () => {
		setMenuIsOpen( false );
	};

	const openSearchForm = () => {
		setSearchFormIsOpen( true );
	};

	const closeSearchForm = () => {
		setSearchFormIsOpen( false );
	};

	// Ensure only the menu or search form is open.
	useEffect( () => {
		if ( wasSearchFormOpen && isMenuOpen ) {
			closeSearchForm();
		} else if ( wasMenuOpen && isSearchFormOpen ) {
			closeMenu();
		}
	}, [ isMenuOpen, isSearchFormOpen, wasMenuOpen, wasSearchFormOpen ] );

	return (
		<HeaderContext.Provider value={ {
			isMenuOpen,
			openMenu,
			closeMenu,
			isSearchFormOpen,
			openSearchForm,
			closeSearchForm,
		} }>
			{ children }
		</HeaderContext.Provider>
	);
}
