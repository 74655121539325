/**
 * External dependencies
 */
import { select } from '@wordpress/data';

/**
 * Internal dependencies
 */
import {
	checkResponseStatus,
	parseResponse,
	parseFailureResponse,
} from './';
import fetchAll from './fetch-middlewares/fetchAll';
import relativeUrl from './fetch-middlewares/relativeUrl';
import rootUrl from './fetch-middlewares/rootUrl';

/**
 * Default set of header values which should be sent with every request unless
 * explicitly provided through apiFetch options.
 *
 * @type {Object}
 */
const DEFAULT_HEADERS = {
	/*
	 * The backend uses the Accept header as a condition for considering an
	 * incoming request as a REST request. See: https://core.trac.wordpress.org/ticket/44534
	 **/
	Accept: 'application/json, */*;q=0.1',
};

/**
 * Default set of fetch option values which should be sent with every request
 * unless explicitly provided through apiFetch options.
 *
 * @type {Object}
 */
const DEFAULT_OPTIONS = { credentials: 'omit' };

const middlewares = [
	fetchAll,
	relativeUrl,
];

if ( 'string' === typeof process.env.REACT_APP_API_ROOT_URL ) {
	middlewares.push( rootUrl( process.env.REACT_APP_API_ROOT_URL ) );
}

const fetchHandler = ( options ) => {
	const {
		url,
		path,
		data,
		parse = true,
		...remainingOptions
	} = options;

	let {
		body,
		headers,
	} = options;

	// Merge explicitly-provided headers with default values.
	headers = {
		...DEFAULT_HEADERS,
		...headers,
	};

	// Include JWT in request user is authenticated and token exists.
	const store = select( 'biz/data' );
	if ( store.isAuthenticated() ) {
		const accessToken = store.getToken();
		if ( accessToken ) {
			headers.Authorization = `Bearer ${ accessToken }`;
		}
	}

	// The `data` property is a shorthand for sending a JSON body.
	if ( data ) {
		body = JSON.stringify( data );
		headers[ 'Content-Type' ] = 'application/json';
	}

	const responsePromise = window.fetch(
		url || path,
		{
			...DEFAULT_OPTIONS,
			...remainingOptions,
			body,
			headers,
		},
	);

	return responsePromise
		.then( checkResponseStatus )
		.then( ( response ) => {
			if ( ! parse ) {
				return response;
			}

			return parseResponse( response );
		} )
		.catch( ( response ) => {
			if ( ! parse ) {
				throw response;
			}

			return parseFailureResponse( response );
		} );
};

function apiFetch( options ) {
	const steps = [ ...middlewares, fetchHandler ];

	const createRunStep = ( index ) => ( workingOptions ) => {
		const step = steps[ index ];
		if ( index === steps.length - 1 ) {
			return step( workingOptions, null );
		}

		const next = createRunStep( index + 1 );
		return step( workingOptions, next );
	};

	return createRunStep( 0 )( options );
}

export default apiFetch;
