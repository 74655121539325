/**
 * External dependencies
 */
import React from 'react';
import { useSelect, useDispatch } from '@wordpress/data';
import classnames from 'classnames';
import { filter } from 'lodash';

/**
 * Internal dependencies
 */
import NoticeList from './List';
import SnackbarList from '../Snackbar/List';

interface NoticesProps {
	className?: string;
}

function Notices( { className }: NoticesProps ) {
	const { notices = [] } = useSelect( ( select ) => {
		const { getNotices } = select( 'biz/data' );
		return { notices: getNotices() };
	}, [] );
	const { removeNotice } = useDispatch( 'biz/data' );

	const defaultNotices = filter( notices, { type: 'default' } );
	const snackbarNotices = filter( notices, { type: 'snackbar' } );

	return (
		<>
			<NoticeList
				className={ classnames( 'notices__default', className ) }
				notices={ defaultNotices }
				onRemove={ removeNotice }
			/>
			<SnackbarList
				className={ classnames( 'notices__snackbar', className ) }
				notices={ snackbarNotices }
				onRemove={ removeNotice }
			/>
		</>
	);
}

export default Notices;
