/**
 * External dependencies
 */
import React from 'react';

/**
 * Internal dependencies
 */
import { Nav, NavLink } from '../Nav';
import SearchButton from './SearchButton';

import './MainNavigation.scss';

/**
 * Check if current location is part of the apprenticeships routes
 */
const isApprenticeshipRoutes = ( match, location ) => {
	if ( location.pathname ) {
		const pathname = location.pathname.split( '/' )[ 1 ];
		const isActive = pathname === 'schnuppern-lehrstellen' || pathname === 'schnupperlehre' || pathname === 'lehrstelle';
		return isActive;
	}
};

const MainNavigation = ( { onClick } ) => {
	return (
		<Nav className="main-navigation">
			<ul>
				<li>
					<NavLink onClick={ onClick } to="/berufe">Berufe</NavLink>
				</li>
				<li>
					<NavLink onClick={ onClick } to="/mittelschulen">Mittelschulen</NavLink>
				</li>
				<li>
					<NavLink onClick={ onClick } to="/veranstaltungen">Veranstaltungen</NavLink>
				</li>
				<li>
					<NavLink onClick={ onClick } to="/schnuppern-lehrstellen" isActive={ isApprenticeshipRoutes }>Schnuppern &amp; Lehrstellen</NavLink>
				</li>
				<li>
					<NavLink onClick={ onClick } to="/firmen">Firmen</NavLink>
				</li>
			</ul>
			<SearchButton className="navigation__item" />
		</Nav>
	);
};

export default MainNavigation;
