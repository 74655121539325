/**
 * External dependencies
 */
import {
	registerStore,
	plugins,
	use,
} from '@wordpress/data';
import { upperFirst } from 'lodash';

/**
 * Internal dependencies
 */
import reducer from './store/reducer';
import * as actions from './store/actions';
import * as selectors from './store/selectors';
import * as resolvers from './store/resolvers';
import controls from './store/controls';
import { AppState } from './store/types';

const DEFAULT_ENTITIES = [
	{ name: 'events' },
	{ name: 'professions' },
	{ name: 'offices' },
	{ name: 'searchResults' },
	{ name: 'apprenticeships' },
	{ name: 'apprenticeshipProfessions' },
	{ name: 'apprenticeshipLocations' },
	{ name: 'organizations' },
];

// The entity selectors are shortcuts to their generic equivalents.
// (getEntityRecord, isEntityRecordsFetching, getEntityRecordsPagination)
const entitySelectors = DEFAULT_ENTITIES.reduce( ( result, entity ) => {
	const { name } = entity;
	const nameTransformed = upperFirst( name );

	result[ `get${ nameTransformed }` ] = ( state, ...args ) => selectors.getEntityRecords( state, name, ...args );
	result[ `is${ nameTransformed }Fetching` ] = ( state, ...args ) => selectors.isEntityRecordsFetching( state, name, ...args );
	result[ `get${ nameTransformed }Pagination` ] = ( state, ...args ) => selectors.getEntityRecordsPagination( state, name, ...args );

	return result;
}, {} );

use( plugins.persistence, { storageKey: 'biz' } );

const store = registerStore<AppState>( 'biz/data', {
	// @ts-ignore
	reducer,
	selectors: {
		...selectors,
		...entitySelectors,
	},
	actions,
	// @ts-ignore
	controls,
	// @ts-ignore
	resolvers,
	persist: [ 'token' ],
} );

export default store;
