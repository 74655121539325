/**
 * Polyfills for browser support.
 */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

/**
 * External dependencies
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { dispatch } from '@wordpress/data';
import { BrowserRouter as Router } from 'react-router-dom';
import { init as sentryInit } from '@sentry/react';
import ReactGA from 'react-ga';

/**
 * Internal dependencies
 */
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './index.scss';
import App from './App';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Userlike from './components/Messenger/Userlike';
import { MatomoAnalyticsProvider } from './components/Analytics';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import { HelmetProvider } from 'react-helmet-async';
import { STORE_KEY } from './store/constants';

import './store';

// IE11 polyfill for Element.matches().
if ( ! Element.prototype.matches ) {
	// @ts-ignore
	Element.prototype.matches = Element.prototype.msMatchesSelector;
}

// Error logging with Sentry.io.
if ( 'production' === process.env.NODE_ENV ) {
	sentryInit( {
		dsn: process.env.REACT_APP_SENTRY_DSN,
		release: process.env.REACT_APP_RELEASE,
		environment: process.env.REACT_APP_ENVIRONMENT,
		ignoreErrors: [
			// Ignore errors for failed chunk loads.
			/Loading( CSS)? chunk [\d]+ failed/i,
			/^Identifier 'change_ua' has already been declared$/,
			'instantSearchSDKJSBridgeClearHighlight',
			// https://github.com/getsentry/sentry-javascript/issues/3040.
			'TypeError: undefined is not an object (evaluating \'window.webkit',
		],
		denyUrls: [
			// Chrome extensions.
			/extensions\//i,
			/^chrome:\/\//i,
			// Userlike widget.
			/userlike-cdn-widgets\.s3-eu-west-1\.amazonaws\.com/i,
		],
	} );
}

// Set up Google Analytics.
if ( process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID ) {
	ReactGA.initialize( process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID, { gaOptions: { siteSpeedSampleRate: 100 } } );
	ReactGA.set( {
		anonymizeIp: true,
		forceSSL: true,
	} );
}

ReactDOM.render(
	<React.StrictMode>
		<ErrorBoundary>
			<Router>
				<ScrollToTop />
				<HelmetProvider>
					<MatomoAnalyticsProvider>
						<App />
						<Userlike />
					</MatomoAnalyticsProvider>
				</HelmetProvider>
			</Router>
		</ErrorBoundary>
	</React.StrictMode>,
	document.getElementById( 'root' ),
);

const serviceWorkerOnUpdate = ( registration ) => {
	const waitingServiceWorker = registration.waiting;
	if ( ! waitingServiceWorker ) {
		return;
	}

	dispatch( STORE_KEY ).createNotice(
		'info',
		'Eine neue Version ist verfügbar.',
		{
			id: 'SERVICE_WORKER_UPDATE',
			type: 'snackbar',
			isPersistent: true,
			action: {
				label: 'Jetzt aktualisieren',
				onClick: () => {
					waitingServiceWorker.addEventListener( 'statechange', ( event ) => {
						if ( 'activated' === event.target.state ) {
							window.location.reload();
						}
					} );

					waitingServiceWorker.postMessage( { type: 'SKIP_WAITING' } );
				},
			},
		},
	);
};
serviceWorkerRegistration.register( { onUpdate: serviceWorkerOnUpdate } );
