/**
 * External dependencies
 */
import { event as trackEventGA } from 'react-ga';
import { useMatomo } from '@datapunt/matomo-tracker-react';

type TrackEventParams = {
	category: string;
	action: string;
	label?: string;
	value?: number;
}

const useAnalytics = () : {
	trackEvent: ( params: TrackEventParams ) => void | undefined;
} => {
	const { trackEvent: trackEventMatomo } = useMatomo();

	const trackEvent = ( {
		category,
		action,
		label,
		value,
	}: TrackEventParams ) => {
		trackEventGA( {
			category,
			action,
			label,
			value,
		} );
		trackEventMatomo( {
			category,
			action,
			name: label,
			value,
		} );
	};

	return { trackEvent };
};

export default useAnalytics;
